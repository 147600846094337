import React from "react"
import { Box, Flex } from "rebass"
import Button from "@components/button"
import Modal from "react-modal"
import MailchimpForm from "@components/mailchimpFormMobileDownload.js"

const customStyles = {
  overlay: {
    backgroundColor: "rgba(76, 76, 76, 0.88)",
  },
  content: {
    top: "15vh",
    bottom: null,
  },
}

const MobileDownloadCTA = props => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <Flex flexDirection="column">
          <span
            onClick={closeModal}
            style={{ alignSelf: "flex-end", cursor: "pointer" }}
          >
            x
          </span>
          <h2 style={{ textAlign: "center" }}>Get The Download Link</h2>
          <div>
            Dynobase is available for Mac, Windows & Linux. Enter your email
            address to receive the download link.
          </div>
          <Box mt={32}>
            <MailchimpForm thisWeekInDynamoDB />
          </Box>
        </Flex>
      </Modal>
      <Box display={["block", "block", "none"]}>
        <Button
          primary
          onClick={openModal}
          style={{
            display: "flex",
            margin: "20px auto 10px",
            cursor: "pointer",
            outline: "none",
            justifyContent: "center",
            alignItems: "center",
          }}
          linkClassName="dynobase-download-button"
          buttonClassName="dynobase-download-button-btn"
        >
          <img
            src={require("@images/letter.svg")}
            alt="get dynobase download link"
            style={{
              height: "26px",
              width: "26px",
              color: "white",
              verticalAlign: "top",
              margin: "auto 7px auto auto",
            }}
          />
          <span>Email Me The Download Link</span>
        </Button>
      </Box>
    </React.Fragment>
  )
}

export default MobileDownloadCTA
