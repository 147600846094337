import React, { useState } from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import Button from "../components/button"
import { Flex } from "rebass"
import { Input } from "@rebass/forms"

const url =
  "https://gmail.us3.list-manage.com/subscribe/post?u=0caf4907e308df988663e8fcf&amp;id=841ee66f2f"

export default ({ customInputStyle, notPrimaryButton }) => {
  const [email, setEmail] = useState("")
  const [status, setStatus] = useState("")
  return (
    <Flex
      id="start-mobile-download"
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => {
          setStatus(
            message
              ? "<span>Thank you, please check your email for the download link</span>"
              : null
          )
          return (
            <form
              onSubmit={e => {
                e.preventDefault()
                console.log(e)
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Input
                value={email}
                onChange={e => setEmail(e.target.value)}
                id="name"
                name="name"
                placeholder="Your email"
                style={{
                  outline: "none",
                  borderRadius: 5,
                  padding: "9px",
                  minWidth: "250px",
                }}
                {...customInputStyle}
              />
              <Button
                primary={notPrimaryButton ? null : true}
                style={{
                  display: "table",
                  margin: "10px auto",
                  outline: "none",
                  cursor: "pointer",
                  borderRadius: 0,
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                }}
                type="submit"
                onClick={e => {
                  let finalSubscribeObj = {
                    EMAIL: email,
                  }

                  finalSubscribeObj["group[26137][2]"] = 1

                  e.preventDefault()
                  subscribe(finalSubscribeObj)
                  setStatus(
                    "<span>Thank you, please check your email for the download link</span>"
                  )
                }}
              >
                <span>Submit</span>
              </Button>
            </form>
          )
        }}
      />
      <div
        dangerouslySetInnerHTML={{ __html: status }}
        style={{
          fontSize: "0.8em",
          marginTop: "-20px",
          color: "black",
        }}
      />
    </Flex>
  )
}
