import React, { useState } from "react"
import { Box } from "rebass"
import MobileDownloadCTA from "@components/MobileDownloadCTA"
import DesktopDownloadForm from "@components/mailchimpFormDesktopDownload.js"
import { getImage, getImageNode } from "../../../utils/getImage"
import Img from "gatsby-image"

const Hero = props => {
  const [isVideo, setVideo] = useState(false)
  const heroImg = getImageNode("dynobase.png", props.data.allImageSharp)

  return (
    <React.Fragment>
      <h1
        style={{
          color: "white",
          fontWeight: 600,
          fontSize: "2.5rem",
          textAlign: "center",
          marginTop: "62px",
          marginBottom: "1rem"
        }}
      >
        Professional GUI Client for Elasticsearch
      </h1>
      <p
        style={{
          color: "#B6E0FE",
          fontWeight: 400,
          fontSize: "1rem",
          textAlign: "center",
        }}
      >
        Accelerate your Elasticsearch workflow with faster data exploration, code
        generation, bookmarks, and more.
      </p>
      <Img
          fluid={heroImg}
          fadeIn={false}
          // durationFadeIn={500}
          critical={true}
          style={{
            boxShadow: "6px 8px 12px 7px hsla(0, 0%, 0%, 0.12)",
            borderRadius: 5,
            marginBottom: "2.5em"
          }}
      />
      <MobileDownloadCTA />
      <Box display={["none", "none", "block"]}>
        <DesktopDownloadForm />
      </Box>
      <p
        style={{
          textAlign: "center",
          color: "rgba(0,0,0,0.7)",
          fontSize: "0.7em",
        }}
      >
        Available for Mac, Windows and Linux 
      </p>
    </React.Fragment>
  )
}

export default Hero
