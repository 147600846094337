import React from "react"
import { Box, Flex } from 'rebass'
import AboutMenu from './Menus/About'
import DynamoDBMenu from './Menus/DynamoDB'
import ProductFeatureMenu from './Menus/ProductFeatures'
import FreeToolsMenu from './Menus/FreeTools'

const Footer = ({customStyle, noMarginTop}) => (
    <Box 
      style={{
        backgroundColor: "#2680C2",
        ...customStyle
      }}
      sx={{
        mt: noMarginTop ? null : [62, 62, 92],
        width: ['100%'],
        color: 'white',
        p: [20]
      }}
    >
        <p style={{ textAlign: "center", marginBottom: 0}}>© 2020 ES Workbench</p>
    </Box>
)

export default Footer