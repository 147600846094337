import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Footer from "@components/footer/index"
import Pricing from "@components/pages/home/Pricing/index"
import EvenMoreFeatures from "@components/pages/home/EvenMoreFeatures"
import UnleashThePower from "@components/pages/home/UnleashThePower"
import Testimonials from "@components/pages/home/Testimonials"
import Hero from "@components/pages/home/Hero"
import Container from "@components/container"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          backgroundColor: "#2680C2",
          overflow: "hidden",
          width: "100%",
          height: "750px",
          msTransform: "skewY(-6deg)",
          transform: "skewY(-6deg)",
          WebkitTransform: "skewY(-6deg)",
          top: -300,
          zIndex: -900,
        }}
      >
        {" "}
      </div>
      <div
        style={{
          position: "absolute",
          backgroundColor: "#DCEEFB",
          overflow: "hidden",
          width: "100%",
          height: "760px",
          msTransform: "skewY(-6deg)",
          transform: "skewY(-6.6deg)",
          WebkitTransform: "skewY(-6.2deg)",
          top: -280,
          zIndex: -1000,
        }}
      >
        {" "}
      </div>
      <Layout>
        <SEO
          title="ES Workbench - Professional Elasticsearch GUI Client"
          description="Modern Elasticsearch IDE Client. Accelerate Elasticsearch workflow with sleek Admin UI, visual query builder, codegen and more! Available for Mac, Windows &amp; Linux."
          canonical="https://esworkbench.com/"
        />
        <Container>
          <Hero data={data} />
        </Container>
        <Footer />
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allImageSharp {
      nodes {
        fluid(maxWidth: 910, quality: 80) {
          originalName
          presentationWidth
          presentationHeight
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
