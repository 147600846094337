import React, { useState } from "react"
import Button from "./button"
import { Flex } from "rebass"
import { Input } from "@rebass/forms"
import axios from "axios";

const url =
  "https://gl3tpk7d94.execute-api.us-east-1.amazonaws.com/prod/contact-form"

export default ({ customInputStyle, notPrimaryButton }) => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <Flex
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {
        !success &&
          <form
              onSubmit={e => {
                e.preventDefault()
                console.log(e)
              }}
              style={{
                display: success ? "none" : "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <Input
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="email"
                name="name"
                isRequired={true}
                placeholder="Your email"
                style={{
                  outline: "none",
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5,
                  borderRight: "none",
                  padding: "9px",
                  minWidth: "250px",
                }}
                {...customInputStyle}
              />
              <Button
                primary={notPrimaryButton ? null : true}
                style={{
                  display: "table",
                  margin: "10px auto",
                  width: "67%",
                  outline: "none",
                  cursor: "pointer",
                  borderRadius: 0,
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                }}
                type="submit"
                onClick={e => {
                  let finalSubscribeObj = {
                    email,
                    location: window.location.href,
                    createdAt: new Date(),
                  }

                  e.preventDefault()

                  if (email) {
                    setHasError(false)
                    axios.post(url, finalSubscribeObj)
                    .then(function (response) {
                      setSuccess(true);
                    })
                    .catch(function (error) {
                      setHasError(true);
                      setErrorMessage("There was an error submitting your email, please try again.")
                    });
                  } else {
                    setHasError(true)
                    setErrorMessage(
                      "Please enter your email address to continue"
                    )
                  }
                }}
              >
                <span>Request Access</span>
              </Button>
            </form>
      } 
      {success && (
        <div style={{ textAlign: "center", margin: "20px auto 10px" }}>
          <h2>Success</h2>
          <p>
            Thanks for signing up to the launch list, we'll keep you posted.
          </p>
        </div>
      )}
      {hasError && (
        <div style={{ textAlign: "center", margin: "5px auto 10px" }}>
          <p style={{ color: "red"}}>
            {errorMessage}
          </p>
        </div>
      )}
    </Flex>
  )
}
